* {
  font-family: 'Alegreya Sans', sans-serif;
  color: black;
  /* background-color: black; */
}
h1,h2,h3,h4,p {
  background-color: transparent;
}
.App {
  text-align: center;
}

.App-logo {
  height: 100%;
  pointer-events: none;
  width: 100%;
  position: absolute;
  z-index: -10;
}

@media only screen and (max-width: 1200px) {
  .partners-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
  }
  .partner {
    width: 350px;
    height: auto;
    background-color: whitesmoke;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 25px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.about-link {
  color: #004EFF;
  text-decoration: underline;
  font-size: xx-large;
  text-shadow: 2px 2px 8px black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* NAVBAR */
.nav {
  display: flex;
  flex-direction: row;
  height: auto;
}
.nav img{
  height: 75px;
  padding-left: 15px;
}
.nav-link {
  padding: 10px;
  float: right;
}

/* HOME PAGE */
.tagline-content{
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
.tagline {
  font-size: 8vw;
  color: white;
  text-shadow: 2px 2px 8px black;
  background-color: transparent;
}
.about-btn {
  width: 125px;
  padding: 10px;
  border: none;
  border-radius: 7px;
  background-color: #004EFF;
  color: whitesmoke;
  font-weight: bold;
  font-size: large;  
  transition: 0.3s ease-in-out 0s;
  margin-top: 15px;
}
.about-btn:hover {
  transform:scale(1.1,1.1);
}

/* ABOUT PAGE */
.about-header {
  background-color: #004EFF;
  background: url('/src/images/about-banner.jpeg');
  background-size: 100% auto;
  /* overflow: hidden; */
  background-position: bottom 20% right 0%;
}
.about-header h1 {
  text-align: center;
  margin-top: auto;
  font-size: 50px;
  padding: 75px;
  color: whitesmoke;
  text-shadow: 2px 2px 8px black;
}
.about-content {
  padding: 50px;
  text-align: left;
  max-width: 1000px;
  margin: auto;
  height: max-content;
}
.about-content h2{
  text-align: left;
  margin-bottom: 20px;
}
.about-content p {
  font-size: large;
}
.tab {
  background-color: white;
}
/* .investment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.investment {
  display: flex;
  flex-direction: column;
  background-color: rgb(40, 44, 52);
  width: 40%;
  padding: 20px;
} */
.partners-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
  border-radius: 10px;
}
.partner-img {
  width: 200px;
  padding: 20px;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}
.partner {
  width: 100%;
  height: auto;
  background-color: whitesmoke;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 25px;
  margin: 25px;
  
}
.fndr-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  background-color: white;
  border-radius: 100px;
  height: 150px;
  width: 150px;
}

/* CONTACT */
.form-container {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 100px;
  text-align: left;
  height: auto;
  display: flex;
  flex-direction: column;
}
.section {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #004EFF;
  background-color: transparent;
  margin: 20px;
  width: 100%;
}
.submit-btn {
  width: 100px;
  border: none;
  height: 50px;
  margin-top: 25px;
  border-radius: 10px;
  background-color: #004EFF;
  color: whitesmoke;
  margin-left: auto;
  margin-right: auto;
}

/* LEGAL */
.legal-header {
  background: url('/src/images/legal-banner.jpeg');
  background-size: 100% auto;
  overflow: hidden;
  background-position: center;
}
.legal-header h1 {
  text-align: center;
  margin-top: auto;
  padding: 100px;
  color: #004EFF;
  text-shadow: 2px 2px 8px black;
}

/* FOOTER */
.py-5.bg-dark.fixed-bottom {
  height: 30px;
  padding: 5px !important;
  background-color: black !important;
  display: flex;
  flex-direction: row;
}
.ft-link{
  padding-left: 10px;
  padding-right: 10px;
  color: whitesmoke;
}
/* .ft-link:hover {
  opacity: 0.7;
  color: whitesmoke;
} */
.vl {
  border-left: 1px solid white;
  height: 90%;
}
.copyright-container {
  margin-left: auto;
  color: white !important;
  margin-top: auto;
}
.copyright-container p{
  margin: 0 !important;
  color: white;
}

.grid {
  display: flex;
  flex-direction: row;
}

.partner-bio {
  padding: 30px;
}